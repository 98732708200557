import { getLoopCharacteristics } from '@/services/BPMNEditor/infrastructure/utils/utils'
import Modeler from 'bpmn-js/lib/Modeler'
import ElementFactory from '@/services/BPMNEditor/infrastructure/service/ElementFactory'

export enum MultiTaskType {
    parallel = 'parallel',
    consistently = 'consistently'
}

export default class MultiTaskUtils {
  public static isMultiTask (element) {
    return !!getLoopCharacteristics(element)
  }

  public static getMultiTaskCreateType (element) {
    const loopCharacteristics = getLoopCharacteristics(element)
    if (!loopCharacteristics) {
      return MultiTaskType.parallel
    }
    return loopCharacteristics.isSequential ? MultiTaskType.consistently : MultiTaskType.parallel
  }

  public static buildLoopCharacteristics (
    modeler: Modeler,
    isMultiTask: boolean,
    type: MultiTaskType,
    collectionName: string,
    collectionItem: string
  ):
        { businessObject: object } | null {
    if (isMultiTask) {
      return ElementFactory.build(modeler, {
        type: 'MultiInstanceLoopCharacteristics',
        properties: {
          'isSequential': type === MultiTaskType.consistently,
          'extension:collection': collectionName,
          'extension:elementVariable': collectionItem
        }
      })
    } else {
      return null
    }
  }
}
